<template>
    <div>
      <div class="about">
        <div class="header">This is browser-based application was built from scratch in Vue.js by:</div>
        <div class="our-about">
          <div class="flex-col">
            <a class="linked-in-link" href="https://github.com/gmenke54">GitHub</a>
            <div class="nut">
            <img class="profile-image" @mouseover="this.show=true" @mouseleave="this.show=false" src="https://media-exp1.licdn.com/dms/image/C5603AQGmr5tzUwS9Kg/profile-displayphoto-shrink_800_800/0/1638995274513?e=1650499200&v=beta&t=DMG-0GKAop8i0H9rWH7e6fhIeDw1G2sZ2GT2XFbwUeo" @click="golinkedin" alt="profile image">
              <div v-if="show" class="about-name">Grant Menke</div>
            </div>
            <a class="linked-in-link" href="https://www.linkedin.com/in/grantmenke/">LinkedIn</a>
          </div>
        </div>
      </div>
      <div>
        <div class="header">Resume</div>
        <img @click=this.download() class="resume-img" src="../assets/grantmenke.png" alt="">
      </div>
      <div class="credits">
        <div class="resources">Resources</div>
        <div class="links">
          <a
            class="linked-in"
            href="https://www.npmjs.com/package/vue-flip"
          >
            Vue-Flip Library
          </a>
          <a class="linked-in" href="https://www.canva.com/">
            Canva
          </a>
          <a
            class="linked-in"
            href="https://developer.edamam.com/food-database-api?gclid=CjwKCAiA9aKQBhBREiwAyGP5lakqJnQWwFcILhq97B-LzDVsCwZHrNsD3qaaJzE3jVqGn6UbFuZ7YBoCfiYQAvD_BwE"
          >
           Nutrient Data API
          </a>
          <a
            class="linked-in"
            href="https://www.linkpreview.net/"
          >
            URL Link Preview API
          </a>
          <a
            class="linked-in"
            href="https://loading.io/css/"
          >
            Loading Wheel CSS
          </a>
          <a
            class="linked-in"
            href="https://vue-chart-3.netlify.app/"
          >
            Chart.js Data Mapping Plugin
          </a>
          <a
            class="linked-in"
            href="https://github.com/vueform/toggle"
          >
            Toggle Button Plugin
          </a>
          <a
            class="linked-in"
            href="https://www.youtube.com/watch?v=LXV4wn8scvM&t=2884s"
          >
            Token Based Authentication
          </a>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'About',
  data: ()=>({
    show: false
  }),
  methods: {
    golinkedin(){
      window.open('https://www.linkedin.com/in/grantmenke/')
    },
   async download(){
      const result = await axios.get(`${window.location.origin}/grantmenke.docx`, { responseType: 'blob' });
      const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'grantmenke_resume';
      link.click();
      URL.revokeObjectURL(link.href);
    },
  }

}
</script>


<style scoped>
.resume-img{
  border-radius: 5px;
  width: clamp(400px, 95vw, 650px)
}
.nut{
  position: relative;
  display: flex;
  justify-content: center;
}
.about-name{
  color: white;
  position: absolute;
  top: 43.5%;
  text-align: center;
  font-weight: 700;
  font-size: 2.4vw
}
a{
  font-weight: 600;
  background-color: white;
}
.header{
  font-size: 20px;
  font-weight: 900;
}
.linkedin{
  margin-top: 4vh;
}
.flex-col{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5vh;
}
.linked-in-link {
  text-decoration: none;
  color: rgba(68, 68, 68, 0.87);
  margin: 0.5vw;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 20vw;
  font-weight: 800;
}

.linked-in {
  text-decoration: none;
  color: rgba(68, 68, 68, 0.87);
  margin-top: 2vh;
  padding: 2vw 4vw 2vw 4vw;
  margin: 0.5vw;
  border-radius: 10px;
}

.linked-in:hover,
.linked-in-link:hover {
  color: #3181CE;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 7px -5px rgba(0,0,0,0.7);
}


.about{
  display: flex;
  flex-direction: column;
  align-items: space-around;
  min-height: 20vh
}

.about,
.credits {
  border-radius: 10px;
  padding: 1.5vw;
  margin: 0 auto;
  width: 70vw;
}

.resources {
  font-size: 20px;
  font-weight: 900;
  cursor: default;
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1vh;
}

img{
  border-radius: 50%;
  width: 18vw;
  cursor: pointer;
  /* border: 3px solid #3181CE; */
}
img:hover{
  /* border: 3px solid #5fa4e4; */
  opacity: 0.75;
}
</style>